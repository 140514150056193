
import { Options, Vue } from 'vue-class-component';
import { StorageServices } from '@/services/StorageServices';
import store from '@/store';

@Options({
    components: {
    }
})
export default class AuthHeader extends Vue {

    exhibitionId: string = store.state.exhibition.identifier;

    created() {
        var exhibition = StorageServices.getExhibition();
        
        if(exhibition)
            this.exhibitionId = exhibition.identifier;
    }
    
    close(){
        if(this.$route.fullPath.indexOf("/staff/") != -1)
            this.$router.replace("/staff/login");
        else
            this.$router.replace('/welcome/' + this.exhibitionId);
    }
}
