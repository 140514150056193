import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "authPage" }
const _hoisted_2 = { class: "text-center mt-4 mb-5 pb-4" }
const _hoisted_3 = { class: "text-center mt-5 mb-4 pb-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_auth_header = _resolveComponent("auth-header")
  const _component_localized_text = _resolveComponent("localized-text")
  const _component_form_field = _resolveComponent("form-field")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_auth_header),
    _createVNode(_component_localized_text, {
      localizedKey: "app_WelcomeBack",
      text: "Welcome back",
      class: "title white d-block text-center mb-4 pb-2"
    }),
    _createVNode("form", {
      onSubmit: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (_ctx.login()), ["prevent"]))
    }, [
      _createVNode(_component_form_field, {
        type: "email",
        modelValue: _ctx.model.username,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.model.username = $event)),
        labelKey: "app_Email",
        labelValue: "Email",
        placeholderKey: "app_Email",
        placeholderValue: "Email"
      }, null, 8, ["modelValue"]),
      _createVNode(_component_form_field, {
        type: "password",
        modelValue: _ctx.model.password,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.model.password = $event)),
        labelKey: "app_Password",
        labelValue: "Password",
        placeholderKey: "app_Password",
        placeholderValue: "Password"
      }, null, 8, ["modelValue"]),
      _createVNode("div", _hoisted_2, [
        _createVNode(_component_localized_text, {
          localizedKey: "app_forgotPassword",
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$router.push('/reset-password'))),
          text: "Forgot password?",
          class: "fs_14 fw_600 white text_underlined"
        })
      ]),
      _createVNode("button", {
        type: "submit",
        disabled: _ctx.isDisable,
        class: "black"
      }, [
        _createVNode(_component_localized_text, {
          localizedKey: "app_Login",
          text: "Login"
        })
      ], 8, ["disabled"])
    ], 32),
    _createVNode("div", _hoisted_3, [
      _createVNode(_component_localized_text, {
        localizedKey: "app_areYouNew",
        text: "Are you new?",
        class: "fs_14 fw_600 white"
      }),
      _createVNode(_component_localized_text, {
        localizedKey: "app_signup",
        onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$router.push('/register'))),
        text: "Sign up",
        class: "fs_14 fw_600 white text_underlined ms-1"
      })
    ])
  ]))
}