import { CommonServices } from '@/services/CommonServices';
import { StorageServices } from '@/services/StorageServices';
import router from '@/router';
import store from '@/store';
import * as OM from '@/model';
import { BaseUserAuthClient } from './services';
import { lang } from 'moment';

class _LoginServices {

    LoginCallback(loginResponse: OM.BaseApiLoginResponse): Promise<void>{
        var prom = new Promise<void>((resolve, reject) => {
            if(!loginResponse.token){
                LoginServices.logout();
                router.replace('/');
                reject();
                return;
            }
    
            StorageServices.setAuthToken(loginResponse.token);
            
            StorageServices.setLoggedUser(loginResponse);
            store.state.loggedUser = loginResponse;

            resolve();
        })
        
        return prom;
    };

    logout() {
        let prom = new Promise<void>((resolve, reject) => {
            CommonServices.destroyToken();
            this.resetLoggedUser();
            resolve();
        })
        return prom;
    }

    private resetLoggedUser() {
        var loggedUser = new OM.BaseApiLoginResponse();
        StorageServices.setLoggedUser(loggedUser);
        store.state.loggedUser = loggedUser;
    }

}

export let LoginServices = new _LoginServices();