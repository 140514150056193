
import { Options, Vue } from 'vue-class-component';
import { BaseUserAuthClient, CustomerAuthClient } from '@/services/services';
import { StorageServices } from '@/services/StorageServices';
import { LoginServices } from '@/services/LoginServices';
import InfoModal from '@/components/modals/infoModal.vue';
import AuthHeader from '../components/authHeader.vue';
import store from '@/store';
import * as OM from '@/model';
import * as VM from '@/viewModel';

@Options({
    components: {
        AuthHeader
    }
})
export default class Login extends Vue {

    model: OM.LanguageCredential = new OM.LanguageCredential();

    created() {
        this.model.preferredCulture = StorageServices.getLanguage();
    }

    get isDisable() {
        return !this.model.username || !this.model.password;
    }

    login() {
        BaseUserAuthClient.login(this.model)
        .then(x => {
            LoginServices.LoginCallback(x)
            .then(y => {
                if(x.role == VM.AppRole.Staff)
                    this.$router.push("/staff/exhibitions")
                else
                    this.$router.push("/exhibition/" +  store.state.exhibition.identifier); 
            });
        })
        .catch(y => {
            this.$opModal.show(InfoModal, {
                text: this.$localizationService.getLocalizedValue("app_LoginCredentialsWrong", "We are sorry but your credentials seems wrong, please check what you wrote for accuracy"),
                confirm: this.$localizationService.getLocalizedValue("app_Retry", "Retry"),
                deny: this.$localizationService.getLocalizedValue("app_Register", "Register"),
                confirmCb: () => {
                    this.$opModal.closeLast()
                },
                denyCb: () => {
                    this.$opModal.closeLast();
                    this.$router.push("/register");
                }
            })
        })
    }
    
}
